<template>
  <v-app>
    <app-bar />
    <drawer />
    <v-progress-linear v-if="loading" indeterminate color="primary"/>
    <main-view v-if="!loading"/>
  </v-app>
</template>
<script>
  export default {
    name: 'DashboardIndex',
    components: {
      AppBar: () => import('./core/AppBar.vue'),
      Drawer: () => import('./core/AppDrawer.vue'),
      MainView: () => import('./core/MainView.vue')
    },
    computed: {
    },
    async mounted () {
      await this.loginWithToken()
    },
    data() {
    return {
      prevRoute: null,
      loading: true
    }
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.prevRoute = from
      })
    },
    methods: {
      async loginWithToken () {
        if(this.prevRoute !== null) {
          if(this.prevRoute.path === '/auth/login'){
            this.loading = false
            return
          }
        }
        await this.$store.dispatch('authentication/doLoginWithToken').then(() => {
          this.loading = false
          this.$store.dispatch('setupWebSockets');
        })
          .catch(() => {
            this.$router.replace(this.$route.query.redirect || '/auth/login')
          })
      },
    },
  }
</script>